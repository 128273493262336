import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import MainBanner from "../components/MainBanner";
import UpcomingEvents from "./UpcomingEvents";
import logo from '../assets/images/logo-dummy.png'
import centrum from '../assets/images/centrum.png'
import runforcause from '../assets/images/runforcause.png'
import axios from "axios";
import Partners from "./Partners";
import Alert from 'react-bootstrap/Alert';

const Home = () => {
    const [loading, setLoading] = useState(false);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [showPara, setShowPara] = useState(false);
    useEffect(() => {
        const currentURL = window.location.href;
        var url = currentURL.split("#")[1]
        let element = document.getElementById('fixtures_section');
        if (url === "team_section") {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        getUpcomingEventsData();
    }, [])

    const getUpcomingEventsData = () => {
        setLoading(true);
        axios
            .get("https://www.itwgo.in/xlri/pages/upcoming_events/")
            .then(res => {
                if (res.status) {
                    setUpcomingEvents(res.data);
                    setLoading(false);
                }
            })
            .catch(error => setLoading(false));
    };

    return (
        <div className="main-wrapper">
            <section>
                <MainBanner />
            </section>

            {/* Sopners and Partners */}
            <Partners />

            <section className="container mb-5">
                <h1 className="title-main text-center mb-4">XLRI Platinum Jubilee runathon for the greater good</h1>
                <Row className="about-section">
                    <Col xs={12} md={4} className="about-section-left ">
                        <Card>
                        <h4 className="text-center">CELEBRATING 75 YEARS</h4>
                        <p className="text-center">XLRI has been at the forefront of management education and research, fostering a culture of excellence and shaping leaders who create positive change in society.</p>
                        </Card>
                    </Col>
                    <Col xs={12} md={4} className="about-section-left">
                        <Card>
                        <h4 className="text-center">XL RUNATHON</h4>
                        <p className="text-center">In honor of our 75th anniversary, XLRI is conducting 10KM, 5KM & 2KM runs across four dynamic cities. It's a celebration of our values, our resilience, and our commitment to the greater good of society.</p>
                        </Card>
                    </Col>
                    <Col xs={12} md={4} className="about-section-left">
                        <Card>
                        <h4 className="text-center">SOCIAL CAUSE</h4>
                        <p className="text-center">It’s more than just a race - it's a race for a cause. The proceeds from the run will go to provide free education for underprivileged individuals, offering them opportunities they might not have otherwise had.</p>
                        </Card>
                    </Col>
                </Row>
            </section>

            <section className="container my-4">
                <h1 className="title-1 mb-3">Upcoming Runs</h1>
                <div className="alert-box mb-3">
                    {/* <Alert key='warning' variant='warning' > */}
                       {/* <b><i size="medium" className="fa fa-bell-o"></i> Important Update:</b> */}
                       {/* <li>Participants in Mumbai are requested to collect their BIBs (Bib Numbers) on March 02, 2024, between 10:00 AM and 7:00 PM, at <a href="https://maps.app.goo.gl/Gk7B4MVD6K7y4qDD7" target="_blank">MMRDA Grounds, We Work, BKC</a></li> */}
                       {/* <li>The Hyderabad Run date has been rescheduled from March 3rd to March 10th, 2024, to ensure an optimal event experience. We appreciate your cooperation and support.</li> */}
                    {/* </Alert> */}

                    {/* <Alert key='danger' variant='danger' >
                       <li>Due to unforeseen circumstances, we regret to inform you that the Delhi Run event is cancelled. We regret the inconvenience caused.</li>
                    </Alert> */}
                </div>

                {/* <div className="alert-box mb-3">
                    <Alert key='danger' variant='danger' >
                       <li>Registrations for the Delhi location is currently paused due to bad weather conditions. We will keep you posted once we resume registrations. We regret the inconvenience caused.</li>
                    </Alert>
                </div>    */}
                <Row className="evt-row">
                    {upcomingEvents?.map(item =>
                        <UpcomingEvents upcomingEvents={item} />
                    )}
                </Row>
            </section>
            <section className="container mt-4 mb-5">
                <h1 className="title-main text-center mb-4">RUN WITH XL</h1>
                <div className="about-section">
                    <p className="text-center">Step into our world, where purpose and passion converge. We take great pride in curating a series of 10K runs spanning the length and breadth of India, a tribute to XL's 75 year legacy. Our mission extends beyond the celebratory—it's a profound homage to the pursuit of knowledge and a resolute commitment to the common good. At the core of our endeavor is the noble cause of advocating for free education and confronting the shadows of social injustice. We ardently believe in the transformative power of education and the urgent need to bridge societal divides. Join us in this significant journey, where we don our running shoes not only to mark a milestone but to champion a cause that ushers in a fairer, more just tomorrow.</p>
                </div>
            </section>
            {/* <section className="container my-4">
                <h1 className="title-main text-left">TITLE SPONSOR</h1>
                <hr />
                <div className="sponser-section">
                    <Row className="pt-3">
                        <Col xs={12} md={3}>
                            <img className="sponser-logo" src={centrum} />
                        </Col>
                        <Col xs={12} md={9}>
                            <p>
                            One of India’s fast-growing and diverse financial services groups, Centrum has been serving the financial and advisory needs of institutions and individuals for two and a half decades.
                            </p>
                            <p className="mb-0">
                            Our Institutional services include Investment Banking and Broking to FIIs, Pension Funds, Indian Mutual Funds, Domestic Institutions etc. We also provide Wealth Management Services to HNIs and Family Offices, Affordable Housing finance in tier 2 & 3 cities and Retail Broking services. Our Asset Management business has funds across Private Debt and Venture Capital.
                            
                            </p>
                            {showPara && (
                            <p className="mb-0 mt-3 show-para">
                            We have been providing MSME Loans and Micro finance for a few years. With these businesses now merged into Unity Small Finance Bank, our most recent venture, they are expected to scale up substantially. We are a PAN India Group with a strong leadership team of seasoned professionals with a successful track record, operating out of 140+ cities and with an international presence in Singapore.
                            </p>
                            )}
                            <Button className="toggle-show" variant="outlined" onClick={()=> setShowPara(!showPara)}>Know {showPara ? "less" : "more"}</Button>
                            
                        </Col>
                    </Row>
                </div>
            </section> */}

            {/* <section className="container my-4">
                <h1 className="title-main text-center">REFUND POLICY</h1>
                <div className="refund-section py-4 px-xs-1">
                    <p className="text-start">We appreciate your interest in participating in our marathon event. Please be aware that we have a strict no refund policy in place. Once you register for the event and complete your payment, your entry is considered final and non-refundable. This policy is in effect regardless of the circumstances, including but not limited to injury, scheduling conflicts, or personal reasons that prevent your participation. We commit substantial resources to planning and organizing the event, and your registration fees directly support these efforts. In the event of unforeseen circumstances, such as event cancellation due to extreme weather or other factors beyond our control, we will make every effort to reschedule the event or offer alternative options for registered participants. Your understanding and support in this matter are greatly appreciated, as they help us ensure the success and sustainability of our marathon event. If you have any questions or concerns, please feel free to contact our event organizers for further information or clarifications. Thank you for your cooperation.</p>
                </div>
            </section> */}
        </div>


    )
};

export default Home;