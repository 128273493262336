import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Form,
  Row,
  Button,
  Dropdown,
  SplitButton,
  InputGroup,
} from "react-bootstrap";
import MainBanner from "../components/MainBanner";
import runforcause from "../assets/images/runforcause.png";
import logo from "../assets/images/logo-dummy.png";

const TermsAndConditions = () => {
  return (
    <div className="main-wrapper">
      <section>
        <MainBanner />
      </section>
      <section className="container my-4">
        <h1 className="title-main text-center pt-4">Refund Policy</h1>
        <div className="refund-section py-4 px-xs-1">
          <p className="text-start">
            We appreciate your interest in participating in our runathon event.
            Please be aware that we have a strict no refund policy in place.
            Once you register for the event and complete your payment, your
            entry is considered final and non-refundable. This policy is in
            effect regardless of the circumstances, including but not limited to
            injury, scheduling conflicts, or personal reasons that prevent your
            participation. We commit substantial resources to planning and
            organizing the event, and your registration fees directly support
            these efforts. In the event of unforeseen circumstances, such as
            event cancellation due to extreme weather or other factors beyond
            our control, we will make every effort to reschedule the event or
            offer alternative options for registered participants. Your
            understanding and support in this matter are greatly appreciated, as
            they help us ensure the success and sustainability of our runathon
            event. If you have any questions or concerns, please feel free to
            contact our event organizers for further information or
            clarifications. Thank you for your cooperation.
          </p>
        </div>
      </section>
      <section className="container my-4">
        <h1 className="title-main text-center pt-4">
          TERMS &amp; CONDITIONS OF PARTICIPATION
        </h1>
        <div className="refund-section py-4 px-xs-1">
          <p>
            <span>A.&nbsp;</span>
            <span>
              <u>DEFINITIONS</u>
            </span>
          </p>
          <p>
            <span>(I)</span>
            <span>&quot;EVENT&quot; means&nbsp;</span>
            <span>XLRI RUNATHON</span>
            <span>&nbsp;(also known as&nbsp;</span>
            <span>XLRUNATHON 2024</span>
            <span>&nbsp;) being an initiative of&nbsp;</span>
            <span>XLRI - SCHOOL OF MANAGEMENT, JAMSHEDPUR.</span>
          </p>
          <p>
            <span>(II)</span>
            <span>
              &ldquo;PARTICIPANT&rdquo; means any person (including a child)
              registering (or being registered) for the EVENT.
            </span>
          </p>
          <p>
            <span>(III)</span>
            <span>&ldquo;EVENT ORGANIZER&rdquo; means&nbsp;</span>
            <span>XLRI - SCHOOL OF MANAGEMENT, JAMSHEDPUR</span>
            <span>
              &nbsp;and to include its affiliates and associates and its/their
              successors or assigns but not limited to the aforestated persons
              and entities.
            </span>
          </p>
          <p>
            <span>(IV)</span>
            <span>
              &ldquo;MERCHANDISE&rdquo; means any item or items (including
              packages) purchased by any PARTICIPANT through the EVENT website /
              online facility.
            </span>
          </p>
          <p>
            <span>(V)</span>
            <span>
              &ldquo;NGO&rdquo; means any non-government organization or Social
              Worker working in support of a social cause or in providing
              support to other NGOs and affiliated or associated with the EVENT.
            </span>
          </p>
          <p>
            <span>(VI)</span>
            <span>
              &ldquo;REGISTRATION FEES/DONATION&rdquo; means the sum of INR 700
              or any other specified amount payable and/or paid by the
              PARTICIPANT for participating in the EVENT.
            </span>
          </p>
          <p>
            <span>B.&nbsp;</span>
            <span>
              <u>
                TERMS &amp; CONDITIONS, ACCEPTANCE OF WAIVER &amp; RELEASE OF
                LIABILITY
              </u>
            </span>
          </p>
          <p>
            <span>1.</span>
            <span>
              &nbsp;PARTICIPANT confirms registering for participation in the
              EVENT on a purely voluntary basis.
            </span>
          </p>
          <p>
            <span>2.</span>
            <span>
              &nbsp;PARTICIPANT taking part in the EVENT is solely responsible
              for selecting a safe route to run and recognizes that
              participation in the EVENT &nbsp;is a potentially hazardous
              activity and willingly assumes all risks associated with such
              participation, including, but not limited to, falls; contact with
              other participants, spectators or others, or vehicular or other
              traffic; the effects of the weather, including heat and/or
              humidity, wind, cold temperature, and wet or slippery surfaces;
              falling tree branches or other overhead objects; traffic; and the
              crowded nature and other conditions of the course chosen by the
              PARTICIPANT, all such risks being known and appreciated by the
              PARTICIPANT.&nbsp;
            </span>
          </p>
          <p>
            <span>3.&nbsp;</span>
            <span>
              PARTICIPANT confirms to follow all precautions required of the
              PARTICIPANT in this event including but not limited to wearing of
              mask in public spaces or the outdoors, social distancing and any
              other guidelines or directives issued by the event manager, the
              local authorities, state authorities or central authorities.&nbsp;
            </span>
          </p>
          <p>
            <span>4.&nbsp;</span>
            <span>
              For any child (age less than 18 years as on date of the event)
              registered by a PARTICIPANT for the event, the PARTICIPANT hereby
              accepts responsibility for the child for all clauses and points
              herein in relation to the child.&nbsp;
            </span>
          </p>
          <p>
            <span>5.&nbsp;</span>
            <span>
              By agreeing to these Terms &amp; Conditions, consent of the
              Parent/Guardian for the participation of their child/children in
              the Event is assumed.&nbsp;
            </span>
          </p>
          <p>
            <span>6.&nbsp;</span>
            <span>
              PARTICIPANT submitting the application for registration for a
              child/children along with her/his/their own, acknowledges that
              running / long-distance running is an extreme sport and may injure
              the body and/or health. PARTICIPANTS registering for children take
              full responsibility for the child/children participating in the
              EVENT and shall not hold the EVENT MANAGER or organizing committee
              or any of its members or entities responsible for any injury or
              accident or harm.&nbsp;
            </span>
          </p>
          <p>
            <span>7.&nbsp;</span>
            <span>
              Children must be above the age of 10 years as on the date of the
              EVENT to participate in the `Run with children&rsquo;
              category.&nbsp;
            </span>
          </p>
          <p>
            <span>8.&nbsp;</span>
            <span>
              PARTICIPANT must be above the age of 14 years as on the date of
              the event to participate in the `10 km Run&rsquo; category.&nbsp;
            </span>
          </p>
          <p>
            <span>9.&nbsp;</span>
            <span>
              PARTICIPANT confirms that the PARTICIPANT&rsquo;s name,
              photographs, and video of their participation and the
              child/children registered by the PARTICIPANT can be used by the
              EVENT MANAGER to publicize the Event.&nbsp;
            </span>
          </p>
          <p>
            <span>10.&nbsp;</span>
            <span>
              PARTICIPANT acknowledges and agrees that any personal information
              of the PARTICIPANT can be stored and used by organizers and/or any
              of its affiliates and/or the NGO supported by the PARTICIPANT for
              any purpose including for the compilation of statistical
              information.&nbsp;
            </span>
          </p>
          <p>
            <span>11.&nbsp;</span>
            <span>
              PARTICIPANT acknowledges that the RUNNER REGISTRATION
              FEES/DONATIONS are for participation in the event and are not, in
              full or in part, a donation or contribution to the NGO supported
              by the participant.&nbsp;
            </span>
          </p>
          <p>
            <span>12.&nbsp;</span>
            <span>
              EVENT MANAGER shall be entitled to suspend, or close registrations
              without prior notice.&nbsp;
            </span>
          </p>
          <p>
            <span>13.&nbsp;</span>
            <span>
              Registration must be completed through the online registration
              platform available in the official website, or by any alternative
              mode indicated by the organizing committee.&nbsp;
            </span>
          </p>
          <p>
            <span>14.&nbsp;</span>
            <span>
              Confirmed registrations are non-transferable and cannot be
              modified except as provided.&nbsp;
            </span>
          </p>
          <p>
            <span>15.&nbsp;</span>
            <span>
              The EVENT MANAGER will not be held responsible for any
              cancellation or rescheduling of the event for reasons beyond their
              control.&nbsp;
            </span>
          </p>
          <p>
            <span>16.&nbsp;</span>
            <span>
              Digital representation of MERCHANDISE is for information only and
              is not a guarantee of actual specifications and appearance of the
              MERCHANDISE.&nbsp;
            </span>
          </p>
          <p>
            <span>17.&nbsp;</span>
            <span>
              Size of MERCHANDISE will be as per generally prevalent or assumed
              norms or practices and the EVENT MANAGER does not guarantee
              fitment for any specific individual or PARTICIPANT.&nbsp;
            </span>
          </p>
          <p>
            <span>18.&nbsp;</span>
            <span>
              Any MERCHANDISE purchased by the PARTICIPANT is on a
              non-exchangeable and non-returnable and non-refundable
              basis.&nbsp;
            </span>
          </p>
          <p>
            <span>19.&nbsp;</span>
            <span>
              MERCHANDISE may be or shall be distributed by the EVENT MANAGER
              and/or designated Vendor of the EVENT MANAGER.&nbsp;
            </span>
          </p>
          <p>
            <span>20.&nbsp;</span>
            <span>
              PARTICIPANT acknowledges that the amount paid towards purchase of
              MERCHANDISE related to the EVENT are not, in full or in part, a
              donation or contribution to the NGO supported by the
              participant.&nbsp;
            </span>
          </p>
          <p>
            <span>21.</span>
            <span>
              &nbsp;Having read these Terms &amp; Conditions and Waiver and
              Assumption of Risk knowing these facts, and in consideration of
              &nbsp;acceptance of the PARTICIPANT&rsquo;s application for the
              EVENT, the PARTCIPANT, waives and releases the EVENT MANAGER and
              all sponsors and officials of the EVENT, and the employees,
              volunteers, and other representatives, agents, and successors of
              each of the foregoing (hereinafter referred to as the
              &ldquo;RELEASEES&rdquo;), from present and future claims and
              liabilities of any kind, known or unknown, arising out of the
              PARTICIPANT&rsquo;s participation in the Events, even though such
              claim or liability may arise out of negligence or fault on the
              part of any of the RELEASEES.
            </span>
          </p>
        </div>
      </section>
      {/* <section className="container mt-5">
            <h1 className="title-main text-center">Terms And Conditions</h1>
            <div className="about-section">
                <p>Celebrating 75 Years of Excellence with XLRI</p>
                <p>Welcome to the XL RUN, an inspiring initiative brought to you by XLRI, on the grand occasion of our 75th anniversary. XLRI has been at the forefront of management education and research, fostering a culture of excellence and shaping leaders who create positive change in society. 
                As we celebrate this magnificent milestone, we invite you to join us in making a difference.</p>
                <p className="mb-0"><b>The XL Run</b></p>
                <p>In honor of our 75th anniversary, XLRI is conducting 10Km, 5km & 2km runs across four dynamic cities. The XL RUN isn't just a race, but a testament to our mission of promoting excellence and creating an impact. It's a celebration of our values, our resilience, and our commitment to the greater good of society.</p>
            
                <p className="mb-0"><b>The Purpose</b></p>
                <p>The purpose of the XL RUN is twofold. Firstly, it is a celebration of our 75 years of dedication to the field of management education and research. However, it also serves as an embodiment of our core values: integrity, excellence, and social responsibility.</p>
            
                <p className="mb-0"><b>Our Values</b></p>
                <p>At XLRI, we believe in the power of education to transform lives and societies. Our core values of integrity, excellence, and social responsibility are the driving forces behind everything we do. Through the XL RUN, we strive to bring these values to life, promoting social justice, and paving the way for a more equitable society.</p>
            
                <p className="mb-0"><b>The Cause</b></p>
                <p>The XL RUN is more than just a race - it's a race for a cause. The proceeds from the run will be used to provide free education for underprivileged individuals, offering them opportunities they might not have otherwise had. By participating in the XL RUN, you're not just running a race, you're taking a stand for social justice and helping to shape a brighter future.</p>
            
                <p>Whether you're an experienced runner or a beginner, we welcome you to join us in the XL RUN. Together, let's celebrate 75 years of XLRI's commitment to excellence.</p>

                <p>Let's run for education. Let's run for social justice. Let's run for a better tomorrow!</p>
            </div>
        </section> */}
      {/* <section className="container mb-4">
            <div className="sponser-section">
                <Row >
                    <Col xs={12} md={12}>
                        <img className="sponser-banner" src={runforcause} />
                    </Col>
                </Row>
            </div>
        </section> */}
    </div>
  );
};

export default TermsAndConditions;
