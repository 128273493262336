import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row ,Button, Dropdown, SplitButton, InputGroup} from "react-bootstrap";
import MainBanner from "../components/MainBanner";
import runforcause from '../assets/images/runforcause.png'
import logo from '../assets/images/logo-dummy.png'

const About = () => {
  
return(
    <div className="main-wrapper">
        <section>
            <MainBanner />
        </section>

        <section className="container mt-5">
            <h1 className="title-about-main text-center">About Us</h1>
            <div className="about-section">
                <p className="text-center text-bold">CELEBRATING 75 YEARS OF EXCELLENCE WITH XLRI</p>
                <p>Welcome to the XL RUN, an inspiring initiative brought to you by XLRI, on the grand occasion of our 75th 
                anniversary. XLRI has been at the forefront of management education and research, fostering a culture of excellence
                and shaping leaders who create positive change in society. As we celebrate this magnificent milestone, we invite you to join us in making a difference.</p>
                <p className="mb-0"><b>THE XL RUN</b></p>
                <p>In honor of our 75th anniversary, XLRI is conducting 10Km, 5km & 2km runs across four dynamic cities. The XL RUN isn't just a race, but a testament to 
                our mission of promoting excellence and creating an impact. It's a celebration of our values, our resilience, and our commitment to the greater good of society.</p>
            
                <p className="mb-0"><b>THE PURPOSE</b></p>
                <p>The purpose of the XL RUN is twofold. Firstly, it is a celebration of our 75 years of dedication to the field of management education and research. However, 
                it also serves as an embodiment of our core values: integrity, excellence, and social responsibility.</p>
                {/*             
                <p className="mb-0"><b>OUR VALUES</b></p>
                <p>At XLRI, we believe in the power of education to transform lives and societies. Our core values of integrity, excellence, and social responsibility are the 
                driving forces behind everything we do. Through the XL RUN, we strive to bring these values to life, promoting social justice, and paving the way for a more equitable society.</p>
                */}
                <p className="mb-0"><b>THE CAUSE</b></p>
                <p>The XL RUN is more than just a race - it's a race for a cause. The proceeds from the run will be used to provide free education for underprivileged individuals, 
                offering them opportunities they might not have otherwise had. By participating in the XL RUN, you're not just running a race, you're taking a stand for social justice and helping to shape a brighter future.</p>
            
                <p>Whether you're an experienced runner or a beginner, we welcome you to join us in the XL RUN. Together, let's celebrate 75 years of XLRI's commitment to excellence.</p>

                <p>Let's run for education. Let's run for social justice. Let's run for a better tomorrow!</p>
            </div>
        </section>
        <section className="container about-section-2 mt-5">
            <h6 className=""><b>To know more about XLRI visit:</b></h6>
            <p className="mb-1"><b>Jamshedpur Campus: </b> <a href="https://xlri.ac.in/" target="_blank">https://xlri.ac.in/</a></p>
            <p><b>Delhi-NCR Campus: </b> <a href="https://xlridelhi.ac.in/" target="_blank">https://xlridelhi.ac.in/</a></p>
        </section>
        <section className="container mb-4">
            <div className="sponser-section">
                <Row >
                    <Col xs={12} md={12}>
                        <img className="sponser-banner" src={runforcause} />
                    </Col>
                </Row>
            </div>
        </section>

    </div>

    
)
};

export default About;